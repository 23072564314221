<template>
  <SupportPageTemplate
    bannerSrc="/assets/image/terms-and-policy_banner.jpg"
    :pageTitle="$t('label.discoverTheJoyOfCookingWithCookXAsia')"
    :pageSubtitle="$t('label.beAnExpertInTheKitchen')"
    :isPDF="true"
    topic="privacyPolicy"
  ></SupportPageTemplate>
</template>

<script>
  import SupportPageTemplate from '@/views/components/support/SupportPageTemplate';
  import axios from 'axios';

  export default {
    name: 'PrivacyPolicy',
    components: {
      SupportPageTemplate
    },
    data: () => ({
      content: null
    }),
    created() {
      this.initPrivacyPolicy();
    },
    methods: {
      getDummyContent() {
        let vm = this;

        axios({
          method: 'get',
          url: '/assets/html/dummy.html'
        })
          .then(function (response) {
            vm.content = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      initPrivacyPolicy() {
        this.getDummyContent();
      }
    }
  };
</script>

<style></style>
